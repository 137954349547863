import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Oidc from "oidc-client";
import { fullConfig } from "./fullConfig";
import styled from 'styled-components';
import Spinner from './components/base/Loading';

const Frame = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const LoginCallbackPage = () => {
  new Oidc.UserManager(fullConfig)
    .signinRedirectCallback()
    .then(user => {
      window.location.replace("/");
    })
    .catch(function(e) {
      console.error(e);
    });
  return <Frame><Spinner /></Frame>;
};

ReactDOM.render(
  <Router>
    <Switch>
      <Route path="/callback" component={LoginCallbackPage} />
      <Route component={App} />
    </Switch>
  </Router>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
