import React from "react";

export default class World extends React.Component {
    render() {
        return (
            <svg width="131" height="111" viewBox="0 0 131 111" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M94.6897 55.7145C93.6966 53.3583 88.742 51.2959 86.8784 50.5913L84.4853 48.4063C84.4037 48.3312 84.3115 48.2667 84.2127 48.214C82.3491 47.2276 78.29 46.76 77.0625 46.6362C76.3223 46.1924 74.3402 45.0518 73.0653 44.7331C71.5152 44.3446 68.0066 45.4087 66.5697 45.8829C66.3353 45.9606 66.1272 46.1028 65.9678 46.2938C64.6337 47.9071 60.2783 53.382 60.2783 56.1136C60.2783 57.482 61.5979 58.7239 63.127 60.1621C63.8224 60.8167 64.9853 61.9111 65.1078 62.2681C65.1434 62.762 65.2777 63.4112 65.4358 64.162C65.7795 65.8096 66.3537 68.5674 65.6531 69.654C63.8237 72.4896 63.3087 77.5878 64.5283 80.7882C65.2948 82.7967 66.5236 83.5131 67.4192 83.7607C67.7695 83.8582 68.133 83.9016 68.5084 83.9016C71.095 83.9016 74.2019 81.7641 76.9835 79.8504C78.2742 78.9615 79.4951 78.1225 80.2063 77.8446C82.6204 76.9003 83.7754 73.775 84.621 71.4939C84.8435 70.8933 85.0951 70.2111 85.2387 69.9859C85.8577 69.0126 87.5408 67.3979 90.246 66.0466C92.942 64.7019 95.9066 58.6093 94.6897 55.7145Z"
                    fill="url(#paint0_linear)"
                />
                <path
                    d="M64.5151 12.8164C42.0031 12.8164 23.6871 31.1324 23.6871 53.6444C23.6871 76.1565 42.0031 94.4724 64.5151 94.4724C87.0272 94.4724 105.343 76.1565 105.343 53.6444C105.343 31.1324 87.0272 12.8164 64.5151 12.8164ZM64.5151 91.8384C55.2367 91.8384 46.7234 88.5102 40.0987 82.9892L42.6774 79.1211C43.7205 78.776 45.405 78.0688 46.0227 76.9572C47.831 73.7028 45.542 68.918 44.8598 67.6339C44.8018 66.8529 44.6161 64.8629 44.1854 63.7843C43.6652 62.4857 41.5132 61.2292 40.3041 60.6076C39.4204 59.0956 37.6569 55.6674 37.6569 53.6431C37.6569 50.7851 40.0183 50.315 40.8296 50.2267C43.8206 49.7104 45.5999 47.569 46.2782 46.5772C47.5425 46.1926 50.3426 45.1785 51.2908 43.5217C52.3049 41.7477 51.1644 37.6385 50.5006 35.6195C50.6744 34.7279 50.9813 32.5693 50.3412 30.757C49.6933 28.9211 48.0588 27.5 47.3437 26.9455C46.9275 26.0012 45.675 23.3764 43.9787 21.4667C48.4921 18.5758 53.647 16.6081 59.1838 15.8311C58.5529 17.1811 58.0327 18.5982 58.4502 19.7479C58.9151 21.0242 60.759 21.9671 62.3012 22.5506C62.1037 24.0573 62.0707 26.5504 63.692 27.8464C65.279 29.116 67.2691 28.5708 68.5848 28.2073C68.752 28.1612 68.9628 28.1032 69.1379 28.0545C69.3394 29.087 69.4448 31.409 69.371 33.6203C69.3592 33.9627 69.4817 34.2959 69.7122 34.5488C69.8583 34.7108 73.3287 38.4999 76.9585 39.0096C77.1442 39.0359 77.3299 39.0477 77.5195 39.0477C80.8147 39.0477 84.6394 35.2771 85.9117 33.9179C87.2959 33.586 91.8818 32.4362 95.2586 31.0125C99.9367 37.3514 102.709 45.1799 102.709 53.6444C102.709 74.7051 85.5758 91.8384 64.5151 91.8384Z"
                    fill="url(#paint1_linear)"
                />
                <path
                    d="M19.5604 44.5127C8.68315 46.8247 2 49.9613 2 53.4157C2 60.5247 30.3035 66.2876 65.2176 66.2876C100.132 66.2876 128.435 60.5247 128.435 53.4157C128.435 49.9613 121.752 46.8247 110.875 44.5127"
                    stroke="#FFA900"
                    strokeWidth="4"
                />
                <path
                    d="M25.2344 79.9862C19.1778 89.3124 16.6701 96.2561 19.1127 98.6987C24.1395 103.726 48.2281 87.787 72.9161 63.099C97.6041 38.411 113.543 14.3224 108.516 9.29557C106.073 6.85292 99.1296 9.3607 89.8034 15.4173"
                    stroke="#FFA900"
                    strokeWidth="4"
                />
                <path
                    d="M40.6317 15.4171C31.3055 9.36052 24.3619 6.85274 21.9192 9.29539C16.8924 14.3222 32.831 38.4108 57.519 63.0988C82.207 87.7868 106.296 103.725 111.322 98.6985C113.765 96.2559 111.257 89.3123 105.201 79.986"
                    stroke="#FFA900"
                    strokeWidth="4"
                />
                <defs>
                    <linearGradient
                        id="paint0_linear"
                        x1="77.6246"
                        y1="44.6494"
                        x2="77.6246"
                        y2="83.9016"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#FFA900" />
                        <stop offset="1" stopColor="#450101" />
                    </linearGradient>
                    <linearGradient
                        id="paint1_linear"
                        x1="64.5151"
                        y1="12.8164"
                        x2="64.5151"
                        y2="94.4724"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#FFA900" />
                        <stop offset="1" stopColor="#450101" />
                    </linearGradient>
                </defs>
            </svg>
        );
    }
}
