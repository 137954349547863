import React, { useState } from "react";
import styled from "styled-components";
import axios from "axios";
import { Title, Text } from "../../components/styles/fonts";
import BankID from "./BankID";
import Spinner from "../../components/icons/InboxSpinner";

async function handleConsent(tasks, setLoading, handleNext) {
  try {
    setLoading(true);
    await axios.post(`/api/onboarding/${tasks[0].flowId}/tasks/${tasks[0].taskId}/complete`, { approved: true });
    setTimeout(() => {
      setLoading(false);
      handleNext("payment-in-progress");
    }, 1000);
  } catch (err) {}
}

export default function AuthenticateConsent(props) {
  const [loading, setLoading] = useState(false);

  if (loading) {
    return (
      <SpinnerFrame>
        <Spinner />
      </SpinnerFrame>
    );
  }

  return (
    <Frame>
      <Title size="24px">Godkjenn betaling</Title>
      <Text size="16px">Vennligst godkjenn handlingen før du går videre. Vinduet lukkes automatisk.</Text>
      <BankID onClick={() => handleConsent(props.state.tasks, setLoading, props.handleNext)} />
    </Frame>
  );
}

const Frame = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SpinnerFrame = styled(Frame)`
  justify-content: center;
  padding-top: 2%;
`;
