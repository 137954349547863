import React from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Accounts from "./pages/accounts";
import StartOnboarding from "./pages/start-onboarding";
import StartPayment from "./pages/start-payment";
import FrontPage from "./pages/front";
import Oidc from "oidc-client";
import { fullConfig } from "./fullConfig";
import Spinner from "./components/base/Loading";
import styled from "styled-components";

const challengeIdentity = () => {
  new Oidc.UserManager(fullConfig)
    .signinRedirect()
    .then(() => { })
    .catch(function (e) {
      console.error(e);
    });
};

class App extends React.Component {
  state = {};

  render() {
    if (!this.state.user)
      return (
        <SpinnerFrame>
          <Spinner />
        </SpinnerFrame>
      );

    return (
      <Router>
        <div>
          <Switch>
            <Route path="/onboarding" component={StartOnboarding} />
            <Route path="/accounts" component={Accounts} />
            <Route path="/payment" render={props => <StartPayment user={this.state.user} />} />
            <Route component={FrontPage} />
          </Switch>
        </div>
      </Router>
    );
  }

  componentDidMount() {
    new Oidc.UserManager(fullConfig).getUser().then(user => {
      this.setState({ user });
      setTimeout(() => {
        if (!this.state.user || this.state.user.expired) challengeIdentity();
      }, 500);
    });
  }
}

const Frame = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const SpinnerFrame = styled(Frame)`
  justify-content: center;
`;

export default App;
