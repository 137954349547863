import styled from "styled-components";
import { ORANGE, SNOW, MEDIUMGRAY2 } from "./colors";

export const Button = styled.button`
  color: ${props => (props.color ? props.color : SNOW)};
  background-color: ${props => (props.disabled ? MEDIUMGRAY2 : ORANGE)};
  margin-bottom:  ${props => (props.marginBottom ? props.marginBottom : "20px")};
  border: none;
  outline: none;
  min-height: 40px;
  padding-left: 20px;
  padding-right: 20px;
  margin-top:  ${props => (props.marginTop ? props.marginTop : "30px")};
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  width: 210px;
  transition: opacity 0.2s ease-out;

  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
`;