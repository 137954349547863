import React, { Component } from "react";
import styled from "styled-components";
import axios from "axios";
import Spinner from "../accounts/InboxSpinner";
import Oidc from "oidc-client";
import { fullConfig } from "../../fullConfig";

const requestPayload = {
  organizationId: "953797437"
};

export class StartOnboarding extends Component {
  state = { onboardingFlowId: "", loading: false, orgNr: requestPayload.organizationId, redirectUrl: window.location.href, user: null, webhookUrl: "" };
  getOnboardingRedirectUrl = host => {
    const localhostFallback = "onboarding.dev-2.ztlpay.io";
    const LOCALHOST = "localhost";
    if (host.indexOf(LOCALHOST) !== -1) {
      return localhostFallback;
    }

    return `${host
      .split(".")
      .map((el, i) => (i === 0 ? "onboarding" : el))
      .join(".")}`;
  };

  render() {
    const { host = "demo.ztl.test" } = window.location;
    const onboardingUrl = `https://${this.getOnboardingRedirectUrl(host)}/onboarding/`;

    return (
      <Frame>
        <MenuFrame>
          <MenuTitle>Demoverktøy - onboarding</MenuTitle>
          <div style={{ flexGrow: 1 }} />
          <MenuTitle style={{ fontSize: 14, fontWeight: 400 }}>Organization number:</MenuTitle>
          <Input
            isValid={this.state.orgNr.length === 9}
            value={this.state.orgNr}
            onChange={e => this.setState({ orgNr: e.target.value })}
          />
          <div style={{ flexGrow: 1 }} />
          <MenuTitle style={{ fontSize: 14, fontWeight: 400 }}>Redirect URL:</MenuTitle>
          <Input
            isValid={/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&/=]*)/.test(this.state.redirectUrl) || this.state.redirectUrl.includes("localhost") ? true : !this.state.redirectUrl}
            value={this.state.redirectUrl}
            onChange={e => this.setState({ redirectUrl: e.target.value })}
          />
          <MenuTitle style={{ fontSize: 14, fontWeight: 400 }}>Webhook URL:</MenuTitle>
          <Input
            value={this.state.webhookUrl}
            onChange={e => this.setState({ webhookUrl: e.target.value })}
          />
          {this.state.loading ? <Spinner /> :
            <Button onClick={this.handleStartOnboardingAsync}>Start onboarding</Button>}
          <div style={{ flexGrow: 1 }} />
          <MenuTitle style={{ fontSize: 14, fontWeight: 400 }}>Onboarding link:</MenuTitle>
          {this.state.onboardingFlowId ? (
            <a
              target="_blank"
              rel="noopener noreferrer"
              style={{ wordBreak: "all", textAlign: "center" }}
              href={`${onboardingUrl}${this.state.onboardingFlowId}`}
            >{`${onboardingUrl}${this.state.onboardingFlowId}`}</a>
          ) : <p style={{ "color": "red" }}>{this.state.error}</p>}
        </MenuFrame>
      </Frame>
    );
  }

  componentDidMount() {
    new Oidc.UserManager(fullConfig).getUser().then(user => {
      this.setState({ user });
    });
  }

  handleStartOnboardingAsync = async () => {
    this.setState({ loading: true });
    this.setState({ error: "" })

    let config = {
      ...requestPayload,
      organizationId: this.state.orgNr.replace(/\s/g, ""),
      redirectUrl: this.state.redirectUrl ? this.state.redirectUrl : "",
      ...(this.state.webhookUrl && {webhookUrl: this.state.webhookUrl} )
    }

    try {
      const res = await axios.post(
        "/api/onboarding",
        {
          ...config
        },
        { secure: false, changeOrigin: true, headers: { Authorization: "Bearer " + this.state.user.access_token } }
      );
      this.setState({ loading: false, onboardingFlowId: res.data.flowId });

    } catch (err) {
      this.setState({ loading: false, error: err })
    }
  };
}

const MenuTitle = styled.h1`
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 20px;
`;

const Input = styled.input`
  width: 150px;
  height: 40px;
  border-radius: 4px;
  outline: none;
  border: ${props => (props.isValid ? "1px solid lightgreen" : "1px solid #eee")};
  padding-left: 10px;
  padding-right: 10px;
  font-size: 16px;
  text-align: center;
`;

const Button = styled.button`
  border: none;
  outline: none;
  color: #fff;
  background-color: #ffa900;
  min-height: 40px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 4px;
  font-size: 14px;
  margin-top: 20px;
  margin-bottom: 40px;

  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
`;

const MenuFrame = styled.div`
  min-height: 500px;
  width: 520px;
  border: 1px solid #eee;
  border-radius: 4px;

  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 20px;
`;

const Frame = styled.div`
  height: 100vh;
  width: 100vw;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export default StartOnboarding;
