import React from "react";
import styled from "styled-components";
import Balance from "./Balance";
import Spinner from "./InboxSpinner";
import axios from "axios";

class App extends React.Component {
  state = {
    loading: false,
    accounts: [
      {accountName: "DNB NOK", accountNumber: "84361759647", balanceBooked: 0, balanceAvailable: 0 },
      {accountName: "SEB NOK", accountNumber: "81246734357922507934", balanceBooked: 0, balanceAvailable: 0 },
      {accountName: "SEB SEK", accountNumber: "50000000056990401214", balanceBooked: 0, balanceAvailable: 0 },
      {accountName: "Nordea SEK", accountNumber: "30000000044910103046", balanceBooked: 0, balanceAvailable: 0 }
    ]
  };

  render() {
    return (
      <AppFrame>
        {this.state.loading ? (
          <SpinnerFrame>
            <Spinner />
          </SpinnerFrame>
        ) : (
          ""
        )}
        <AccountsFrame>
          {this.state.accounts.map(account => {
            return (
              <Balance
                key={account.accountName}
                {...account}
              />
            );
          })}
        </AccountsFrame>
        <Button onClick={() => axios.post("/api/batches")} >Batch</Button>
      </AppFrame>
    );
  }

  componentDidMount() {
    this.fetchBalancesAsync()
    this.interval = setInterval(() => this.fetchBalancesAsync(), 2000);
  }

  fetchBalancesAsync = async () => {
    this.setState({ loading: true });
    const stateCopy = JSON.parse(JSON.stringify(this.state));

    const accounts = await Promise.all(
      stateCopy.accounts.map(async account => {
        const { data } = await axios.get(`/api/v2/bankbalance/${account.accountNumber}`);
        return {
          ...account,
          balanceBooked: data.BalanceBooked,
          balanceAvailable: data.BalanceAvailable
        };
      })
    );

    this.setState({ accounts, loading: false });
  };

  componentWillUnmount() {
    clearInterval(this.interval);
  }
}

const SpinnerFrame = styled.div`
  position: absolute;
  top: 0px;
  right: 20px;
`;

const Button = styled.button`
  border: none;
  outline: none;
  color: #fff;
  background-color: #ffa900;
  min-height: 40px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 4px;
  font-size: 14px;
  margin-top: 20px;
  margin-bottom: 40px;

  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
`;

const AccountsFrame = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 20px;
  grid-column-gap: 60px;
  align-items: center;
  justify-content: center;



  @media (max-width: 800px) {
    grid-template-columns: 1fr;
  }
`;

const AppFrame = styled.div`
  width: 100vw;
  margin-top: 50px;
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export default App;
