import React, { Component } from "react";
import styled from "styled-components";
import ZtlIcon from "./ztl-icon";
import { NavLink } from "react-router-dom";

export class FrontPage extends Component {
  render() {
    return (
      <Frame>
        <MenuFrame>
          <ZtlIconFrame>
            <ZtlIcon />
          </ZtlIconFrame>
          <MenuItemFrame>
            <NavLink style={{ textDecoration: 'none' }} to="/onboarding">
              <MenuTitle >Start onboarding</MenuTitle>
            </NavLink>
          </MenuItemFrame>
          <MenuItemFrame>
            <NavLink style={{ textDecoration: 'none' }} to="/payment">
              <MenuTitle >Start payment</MenuTitle>
            </NavLink>
          </MenuItemFrame>
          <MenuItemFrame>
            <NavLink style={{ textDecoration: 'none' }} to="/accounts">
              <MenuTitle >Account overview</MenuTitle>
            </NavLink>
          </MenuItemFrame>
        </MenuFrame>
      </Frame>
    );
  }
}

const MenuTitle = styled.h1`
  font-size: 16px;
  font-weight: 500;
`;

const MenuItemFrame = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #eee;
  width: 300px;
  height: 50px;

  &:last-child {
      border-bottom: 1px solid #eee;
  }

  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
`;

const ZtlIconFrame = styled.div`
  height: 80px;
  width: 80px;
  margin-bottom: 40px;

  border-radius: 50%;
  border: 1px solid #eee;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.01);
`;

const MenuFrame = styled.div`
  height: 500px;
  width: 420px;
  border: 1px solid #eee;
  border-radius: 4px;

  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 20px;
`;

const Frame = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export default FrontPage;
