import React from "react";

export default class Done extends React.Component {
  render() {
    return (
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20Z"
          fill="#66CD73"
        />
        <path d="M8 12L11 15L17 9" stroke="white" strokeWidth="2" />
      </svg>
    );
  }
}
