import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

export default class Radio extends React.Component {
  render() {
    let opacity = this.props.opacity;
    let color = this.props.color;
    return (
      <RadioFrame>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
          <path
            fill={color}
            fillOpacity={opacity}
            stroke={color}
            strokeWidth={this.props.checked ? 4 : 0}
            d="M12 18c3.314 0 6-2.686 6-6s-2.686-6-6-6-6 2.686-6 6 2.686 6 6 6zm0 2c-4.418 0-8-3.582-8-8s3.582-8 8-8 8 3.582 8 8-3.582 8-8 8z"
          />
        </svg>
      </RadioFrame>
    );
  }
}

const RadioFrame = styled.div`
  path {
    transition: stroke-width 0.2s ease-out;
  }
`;

Radio.propTypes = {
  checked: PropTypes.bool,
  color: PropTypes.string,
  opacity: PropTypes.string
};

Radio.defaultProps = {
  checked: false,
  color: "#B8B8B8",
  opacity: "1"
};
