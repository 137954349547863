import React, { useState } from "react";
import styled from "styled-components";
import GiveConsent from "./GiveConsent";
import SelectAccounts from "./select-accounts/SelectAccounts";
import AuthenticateConsent from "./AuthenticateConsent";
import UnpaidInvoices from "./unpaid-invoices/UnpaidInvoices";
import PaymentInProgress from "./PaymentInProgress";
import AuthenticateInvoices from "./AuthenticateInvoices";
import InvoiceConfirmation from "./InvoiceConfirmation";
import ZtlIcon from "../front/ztl-icon";
import { LIGHTGRAY, SNOW } from "../../components/styles/colors";

function getComponent(comp) {
  switch (comp) {
    case "consent":
      return GiveConsent;
    case "select-accounts":
      return SelectAccounts;
    case "authenticate-consent":
      return AuthenticateConsent;
    case "unpaid-invoices":
      return UnpaidInvoices;
    case "authenticate-invoices":
      return AuthenticateInvoices;
    case "payment-in-progress":
      return PaymentInProgress;
    case "invoice-confirmation":
      return InvoiceConfirmation;
  }
}

export const StartPayment = props => {
  const [comp, setComp] = useState("consent");
  const [state, setState] = useState({});
  const CurrentComp = getComponent(comp);

  return (
    <Frame>
      <Header>
        <ZtlCircle>
          <ZtlIcon />
        </ZtlCircle>
        <p style={{ fontSize: 24, margin: 0, color: "#6C6C6C", paddingBottom: 2 }}>Innovit AS</p>
        <p style={{ fontSize: 16, margin: 0, color: "#6C6C6C" }}>Orgno 123 456 789</p>
      </Header>
      <ContentViewer>
        <CurrentComp state={state} setState={setState} user={props.user} handleNext={comp => setComp(comp)} />
      </ContentViewer>
    </Frame>
  );
};

const ContentViewer = styled.div`
  height: auto;
  width: 60%;

  min-width: 600px;
  max-width: 900px;
  min-height: 500px;
  background-color: ${SNOW};
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2%;
  padding-bottom: 3%;
`;

const Frame = styled.div`
  height: 100vh;
  width: 100vw;
  background-color: ${LIGHTGRAY};

  display: flex;
  flex-direction: column;
  align-items: center;
`;

export default StartPayment;

const Header = styled.div`
  width: 100%;
  min-height: 115px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  position: relative;

  @media (max-width: 700px), (max-height: 700px) {
    min-height: 80px;
  }
`;

const ZtlCircle = styled.div`
  height: 80px;
  width: 80px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  border-radius: 50%;
  margin-left: 20px;

  position: absolute;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  align-self: flex-start;

  @supports not (align-self: flex-start) {
    position: static;
    margin-left: -300px;
  }

  @media (max-width: 700px), (max-height: 700px) {
    transform: scale(0.7);
    margin-left: 0px;
  }

  &:hover {
    opacity: 0.7;
  }
`;
