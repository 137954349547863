const localhostFallback = "oidc.dev-2.ztlpay.io";
const LOCALHOST = "localhost";
const getAuthority = host => {
  if (host.indexOf(LOCALHOST) !== -1) {
    return localhostFallback;
  }

  return `${host
    .split(".")
    .map((el, i) => (i === 0 ? "oidc" : el))
    .join(".")}`;
};

const { protocol = "https:", host = "demo.dev-2.ztlpay.io" } = window.location;
const baseUrl = `${protocol}//${host}`;

const fullConfig = {
  authority: `https://${getAuthority(host)}`,
  client_id: "onboarding",
  redirect_uri: `${baseUrl}/callback`,
  post_logout_redirect_uri: `${baseUrl}/callback-logout`,
  response_type: "code",
  scope: "openid profile payments",
  acr_values: "idp:username-password-provider"
};

module.exports = { fullConfig, getAuthority };
