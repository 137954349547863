import React from "react";
import styled from "styled-components";
import { Title } from "../../components/styles/fonts";
import { Button } from "../../components/styles/base";
import WorldIcon from "../../components/icons/World";


export default function PaymentInProgress(props) {
  setTimeout(() => {
    props.handleNext("invoice-confirmation");
  }, 7500);
  return (
    <Frame>
      <Title size="24px">Betaling</Title>
      <div style={{ paddingTop: "110px", paddingBottom: "80px" }}>
        <WorldIcon />
      </div>
      <Button disabled={true} marginBottom="40px">
        Betaling pågår...
        </Button>
    </Frame>
  );
}

const Frame = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;


