import React from "react";

export default class Check extends React.Component {
  render() {
    var opacity = this.props.opacity;
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <g fill="none">
          <path
            fill="#66CD73"
            fillOpacity={opacity}
            fillRule="nonzero"
            d="M11 13.586l4.793-4.793 1.414 1.414-6.207 6.207-3.707-3.707 1.414-1.414z"
          />
        </g>
      </svg>
    );
  }
}
