export const SNOW = "#FFFFFF";
export const ORANGE = "#FFA900";
export const DEEPRED = "#450101";
export const LIGHTGRAY = "#f8f8f8";
export const MEDIUMGRAY = "#E4E2E2";
export const MEDIUMGRAY2 = "#E5E5E5";
export const GRAY = "#6C6C6C";
export const DARKGRAY = "#484848";
export const NOIR = "#1A1A1A";
export const COALOPACITY = "#707071";
export const COAL2 = "#2B2B2B";
export const COAL = "#20242C";
export const COAL3 = "#666666";
export const COAL4 = "#6C6C6C";
export const COAL5 = "#333333";