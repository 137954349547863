import React from "react";
import styled from "styled-components";
import BankID from "./BankID";
import { Title } from "../../components/styles/fonts";

export default function AuthenticateConsent(props) {
  return (
    <Frame>
      <Title size="24px">Godkjenn tilgang</Title>
      <BankID onClick={() => props.handleNext("unpaid-invoices")} />
    </Frame>
  );
}

const Frame = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
