import React from "react";
import styled from "styled-components";
import onClickOutside from "react-onclickoutside";
import { ORANGE } from "../../../components/styles/colors";

function Popup(props) {
  Popup.handleClickOutside = () => props.onClose();
  const { id, kid, recipient, from, to, amount, currency, foreignCurrency, info, previousInvoices, due } = props.invoice;

  return (
    <Frame>
      <InnerFrame>
        <p style={{ fontWeight: 500, fontSize: 20 }}>Invoice Information</p>
        <DataFrame>
          <DataLeftGrid>
            <Key>ID</Key>
            <Value style={{ textDecoration: "underline", textDecorationColor: ORANGE, color: ORANGE, cursor: "pointer" }}>
              {id}
            </Value>
            <Key>KID</Key>
            <Value>{kid}</Value>
            <Key>Recipient</Key>
            <Value>{recipient}</Value>
            <Key>To account</Key>
            <Value>{to}</Value>
            <Key>Amount</Key>
            <Value>{amount}</Value>
            <Key>Currency</Key>
            <Value>{currency}</Value>
          </DataLeftGrid>
          <DataRightGrid>
            <Key>Info</Key>
            <Value>{info}</Value>
            <Key>Previous invoices</Key>
            <div>
              {previousInvoices.map(invoiceNr => (
                <Value style={{ textDecoration: "underline", textDecorationColor: ORANGE, color: ORANGE, cursor: "pointer", marginLeft: 5, marginRight: 10 }}>
                  {invoiceNr}
                </Value>
              ))}
            </div>
          </DataRightGrid>
        </DataFrame>
      </InnerFrame>
    </Frame>
  );
}

const Key = styled.p`
  margin: 0;
  padding: 0;
`;

const Value = styled.p`
  margin: 0;
  padding: 0;
`;

const DataLeftGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 10px;
  margin-right: 20px;
  margin-left: 20px;
  grid-column-gap: 15px;
`;

const DataRightGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 7fr;
  grid-row-gap: 10px;
  grid-template-rows: 45px;
  grid-column-gap: 15px;
`;

const DataFrame = styled.div`
  display: flex;
  flex-direction: row;
`;

const InnerFrame = styled.div`
  height: 100%;
  width: 100%;
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Frame = styled.div`
  height: 50%;
  width: 80%;
  padding: 20px;
  background-color: #f7f7f7;
  position: absolute;
  z-index: 1;
  border-radius: 4px;
  box-shadow: 1px 3px 5px #ccc;
  justify-self: center;
  margin-top: 9%;
`;

const clickOutsideConfig = {
  handleClickOutside: () => Popup.handleClickOutside
};

export default onClickOutside(Popup, clickOutsideConfig);
