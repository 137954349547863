import React, { useState } from "react";
import styled from "styled-components";
import { ORANGE, COAL4, COAL5, MEDIUMGRAY2 } from "../../../components/styles/colors";
import { Title, TitleMedium } from "../../../components/styles/fonts";
import { Button } from "../../../components/styles/base";
import Spinner from "../../../components/icons/InboxSpinner";
import Popup from "./Popup";
import axios from "axios";
import request from "./request.json";

const invoices = [
  {
    id: "123456789",
    kid: "123456789",
    from: "1234 56 7389",
    to: "1234 56 7859",
    amount: "8000,00",
    currency: "YEN",
    rate: "0.0851",
    nok: "TBA",
    due: "01.02.2020"
  },
  {
    id: "987654321",
    kid: "987654321",
    from: "9876 54 3210",
    to: "9876 54 3210",
    amount: "10000,00",
    currency: "USD",
    rate: "9.1319",
    nok: "TBA",
    due: "01.02.2020"
  },
  {
    id: "010101010",
    kid: "010101010",
    from: "0101 01 0310",
    to: "0101 01 0150",
    amount: "11000.00",
    currency: "NOK",
    rate: "",
    nok: "TBA",
    due: "01.02.2020"
  },
  {
    id: "123123123",
    kid: "1231231233",
    from: "1231 23 1233",
    to: "1231 23 1233",
    amount: "500.00",
    currency: "EUR",
    rate: "10.231",
    nok: "TBA",
    due: "01.02.2020"
  }
];

async function handleProcessStart(setLoading, handleNext, setState, user) {
  setLoading(true);
  const { data: flow } = await axios.post("/api/payments", request, {
    headers: {
      authorization: "Bearer " + user.access_token
    }
  });
  setTimeout(() => {
    const interval = setInterval(async () => {
      const { data } = await axios.get("/api/payments/" + flow.flowId + "/tasks", {
        headers: {
          authorization: "Bearer " + user.access_token
        }
      });
      if (data.tasks.length >= 1) {
        console.log(data);
        clearInterval(interval);
        setState(data);
        handleNext("authenticate-invoices");
      }
    }, 1000);
  }, 1000);
}

export default function SelectAccounts(props) {
  const [selected, setSelected] = useState({});
  const [focusInvoice, setFocusInvoice] = useState(null);
  const [loading, setLoading] = useState(false);
  // TODO: ADD DEFAULT DATA GETTER

  if (loading) {
    return (
      <SpinnerFrame>
        <Spinner />
      </SpinnerFrame>
    );
  }

  return (
    <Frame>
      {focusInvoice ? <Popup changeInvoice={setFocusInvoice} invoice={focusInvoice} onClose={() => setFocusInvoice(null)} /> : ""}
      <Title size="24px">Betalinger til behandling</Title>
      <TitleMedium>Velg alle regninger du vil betale</TitleMedium>
      <table style={{ width: "90%", marginTop: 30 }}>
        <tbody>
          <HeaderRow>
            <th style={{ width: "40px", textAlign: "center", fontSize: "12px", color: "#6C6C6C", paddingLeft: "5px" }}>Velg</th>
            <th style={{ width: "100px", textAlign: "left", fontSize: "12px", color: "#6C6C6C" }}>KID</th>
            <th style={{ width: "100px", textAlign: "left", fontSize: "12px", color: "#6C6C6C" }}>Fra konto</th>
            <th style={{ width: "100px", textAlign: "left", fontSize: "12px", color: "#6C6C6C" }}>Til konto</th>
            <th style={{ width: "80px", textAlign: "center", fontSize: "12px", color: "#6C6C6C", paddingRight: "15px" }}>
              Beløp
            </th>
            <th style={{ width: "50px", textAlign: "left", fontSize: "12px", color: "#6C6C6C" }}>Valuta</th>
            <th style={{ width: "45px", textAlign: "left", fontSize: "12px", color: "#6C6C6C" }}>Valutakurs</th>
            <th style={{ width: "45px", textAlign: "right", fontSize: "12px", color: "#6C6C6C", paddingRight: "15px" }}>NOK</th>
            <th style={{ width: "115px", textAlign: "left", fontSize: "12px", color: "#6C6C6C" }}>Forfallsdato</th>
          </HeaderRow>
          {invoices.map(invoice => {
            const { id, kid, from, to, amount, currency, rate, nok, due } = invoice;
            return (
              <tr id={id} style={{ height: "45px", color: COAL5 }}>
                <td style={{ width: "40px", textAlign: "center", cursor: "pointer", paddingLeft: "5px" }}>
                  <input
                    type="checkbox"
                    value={selected[id] === true}
                    onChange={e => {
                      const newSelected = JSON.parse(JSON.stringify(selected));
                      newSelected[id] = selected[id] ? false : true;
                      setSelected(newSelected);
                    }}
                    style={{ height: "18px", width: "18px" }}
                  />
                </td>
                <td style={{ width: "100px", textAlign: "left", textDecoration: "underline", textDecorationColor: ORANGE }}>
                  {kid}
                </td>
                <td style={{ width: "100px", textAlign: "left" }}>{from}</td>
                <td style={{ width: "100px", textAlign: "left" }}>{to}</td>
                <td style={{ width: "80px", textAlign: "right", paddingRight: "15px" }}>{amount}</td>
                <td style={{ width: "50px", textAlign: "left" }}>{currency}</td>
                <td style={{ width: "45px", textAlign: "left" }}></td>
                <td style={{ width: "45px", textAlign: "right", paddingRight: "15px" }}></td>
                <td style={{ width: "115px", textAlign: "left" }}>{due}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <Button onClick={() => handleProcessStart(setLoading, props.handleNext, props.setState, props.user)}>
        Betal 4 regninger
      </Button>
    </Frame>
  );
}

const Frame = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: "center" table {
    border-spacing: 0;
  }

  table {
    border-spacing: 0;
  }

  td {
    border-bottom: 1px solid ${MEDIUMGRAY2};
  }
`;

const HeaderRow = styled.tr`
  background: linear-gradient(0deg, #f8f8f8, #f8f8f8), #ffffff;
  box-shadow: 0px 1px 0px #e4e2e2, 0px -1px 0px #e4e2e2;
  height: 30px;

  th {
    color: ${COAL4};
    font-family: Muli;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
  }
`;

const SpinnerFrame = styled(Frame)`
  justify-content: center;
  padding-top: 2%;
`;
