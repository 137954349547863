import React from "react";
import styled from "styled-components";
import AccountEntry from "./AccountEntry";
import { Title, Text } from "../../../components/styles/fonts";
import { Button } from "../../../components/styles/base";
import { COAL3, ORANGE, MEDIUMGRAY } from "../../../components/styles/colors";

const accounts = ["NO1234.56.78901", "NO9451.12.98235", "NO4312.56.78123"];

export default function SelectAccounts(props) {
  return (
    <Frame>
      <Title size="24px">Godkjenn tilgang</Title>
      <Title size="20px" padding="10px 0px 0px 0px">
        Du gir herved ZTLs betalingsløsning tilgang til følgende:
      </Title>
      <div style={{ display: "flex", flexDirection: "row", width: "350px", paddingTop: "10px" }}>
        <Text size="18px" color={ORANGE} padding="0 0 5px 5px">•</Text><Text size="18px" color={COAL3} padding="0 0 5px 5px"> Se detaljer fra kontoer under </Text>
      </div>
      <div style={{ display: "flex", flexDirection: "row", width: "350px" }}>
        <Text size="18px" color={ORANGE} padding="0 0 5px 5px">•</Text><Text size="18px" color={COAL3} padding="0 0 5px 5px"> Se saldo fra kontoer under </Text>
      </div>
      <div style={{ display: "flex", flexDirection: "row", width: "350px" }}>
        <Text size="18px" color={ORANGE} padding="0 0 5px 5px">•</Text><Text size="18px" color={COAL3} padding="0 0 5px 5px"> Se transaksjoner fra kontoer under </Text>
      </div>
      <div style={{ borderBottom: `1px solid ${MEDIUMGRAY}`, width: "400px" }}>
        <Title size="18px" padding="10px 0px 0px 0px">Kontoer:</Title>
      </div>
      {accounts.map(account => (
        <AccountEntry key={account} account={account} />
      ))}
      <Text size="16px" padding="20px 0 0 0" color={COAL3} width="500px">
        ZTLs betalingsløsning vil med dette få tilgang til dine kontodetaljer til og med 28. desember 2019.
      </Text>
      <Text size="16px" padding="5px 0 0 0" color={COAL3} width="500px">Kontodetaljer kan hentes av ZTLs betalingsløsning 4 ganger om dagen.</Text>
      <Button marginTop="10px" onClick={() => props.handleNext("authenticate-consent")}>Next</Button>
    </Frame>
  );
}

const Frame = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;
