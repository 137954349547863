import React from "react";
import styled from "styled-components";
import { Title, TitleMedium } from "../../components/styles/fonts";
import { ORANGE, COAL4, COAL5, MEDIUMGRAY2 } from "../../components/styles/colors";
import Done from "../../components/icons/Done";
import Alert from "../../components/icons/Alert";

const invoices = [
  {
    id: "123456789",
    kid: "123456789",
    from: "1234 56 7389",
    to: "YE1234 56 7859",
    amount: "8000,00",
    currency: "YEN",
    rate: "0.0851",
    nok: "8000,00",
    due: "01.02.2020",
    status: "approved"
  },
  {
    id: "987654321",
    kid: "987654321",
    from: "9876 54 3210",
    to: "US9876 54 3210",
    amount: "10000,00",
    currency: "USD",
    rate: "9.1319",
    nok: "10000,00",
    due: "01.02.2020",
    status: "approved"
  },
  {
    id: "010101010",
    kid: "010101010",
    from: "0101 01 0310",
    to: "0101 01 0150",
    amount: "11000.00",
    currency: "NOK",
    rate: "",
    nok: "11000,00",
    due: "01.02.2020",
    status: "approved"
  },
  {
    id: "123123123",
    kid: "1231231233",
    from: "1231 23 1233",
    to: "UK1231 23 1233",
    amount: "500.00",
    currency: "EUR",
    rate: "",
    nok: "",
    due: "01.02.2020",
    status: "failed"
  }
];

export default function SelectAccounts(props) {
  return (
    <Frame>
      <Title size="24px">3 regninger vil bli betalt!</Title>
      <TitleMedium>Gå til regninger til betaling for å legge til flere. Gå på detaljer for å håndtere betalinger som feilet.</TitleMedium>
      <table style={{ width: "90%", marginTop: 30 }}>
        <tbody>
          <HeaderRow>
            <th style={{ width: "40px", textAlign: "center", fontSize: "12px", color: "#6C6C6C", paddingLeft: "5px" }}>Status</th>
            <th style={{ width: "100px", textAlign: "left", fontSize: "12px", color: "#6C6C6C" }}>KID</th>
            <th style={{ width: "100px", textAlign: "left", fontSize: "12px", color: "#6C6C6C", paddingRight: "10px" }}>Fra konto</th>
            <th style={{ width: "125px", textAlign: "left", fontSize: "12px", color: "#6C6C6C" }}>Til konto</th>
            <th style={{ width: "80px", textAlign: "center", fontSize: "12px", color: "#6C6C6C", paddingRight: "15px" }}>Beløp</th>
            <th style={{ width: "50px", textAlign: "left", fontSize: "12px", color: "#6C6C6C" }}>Valuta</th>
            <th style={{ width: "45px", textAlign: "left", fontSize: "12px", color: "#6C6C6C" }}>Valutakurs</th>
            <th style={{ width: "45px", textAlign: "right", fontSize: "12px", color: "#6C6C6C", paddingRight: "15px" }}>NOK</th>
            <th style={{ width: "115px", textAlign: "left", fontSize: "12px", color: "#6C6C6C" }}>Forfallsdato</th>
          </HeaderRow>
          {invoices.map(invoice => {
            const { id, kid, from, to, amount, currency, rate, nok, due, status } = invoice;
            return (
              <tr id={id} style={{ height: "45px", color: COAL5 }}>
                <td style={{ width: "40px", textAlign: "center", paddingLeft: "5px" }}>
                  {status === "approved" ? <Done /> : <Alert />}
                </td>
                <td
                  style={{ width: "100px", textAlign: "left", textDecoration: "underline", textDecorationColor: ORANGE }}
                >
                  {kid}
                </td>
                <td style={{ width: "100px", textAlign: "left" }}>{from}</td>
                <td style={{ width: "125px", textAlign: "left" }}>{to}</td>
                <td style={{ width: "80px", textAlign: "right", paddingRight: "15px" }}>{amount}</td>
                <td style={{ width: "50px", textAlign: "left" }}>{currency}</td>
                <td style={{ width: "45px", textAlign: "left" }}>{rate}</td>
                <td style={{ width: "45px", textAlign: "right", paddingRight: "15px" }}>{nok}</td>
                <td style={{ width: "115px", textAlign: "left" }}>{due}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </Frame >
  )
}

const Frame = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  table {
    border-spacing: 0;
  }

  td {
  border-bottom: 1px solid ${MEDIUMGRAY2};
}
`;

const HeaderRow = styled.tr`
  background: linear-gradient(0deg, #F8F8F8, #F8F8F8), #FFFFFF;
  box-shadow: 0px 1px 0px #E4E2E2, 0px -1px 0px #E4E2E2;
  height: 30px;

  th {
    color: ${COAL4};
    font-family: Muli;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
  }
`;

