import React, { Component } from "react";
import styled from "styled-components";

export default class Balance extends Component {
  render() {
    return (
      <Frame>
        <p>{this.props.accountName}</p>
        <BalanceFrame>
          <p style={{width: 200}} >Booked balance: {this.props.balanceBooked}</p>
          <p style={{width: 200}}>Available balance: {this.props.balanceAvailable}</p>
        </BalanceFrame>
      </Frame>
    );
  }
}

const Frame = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
      padding-bottom: 0;
      margin-bottom: 10px;
  }
`;

const BalanceFrame = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border: 1px solid #eee;
  border-radius: 4px;
  min-height: 250px;
  min-width: 250px;
`;
