import React, { Component } from "react";
import styled from "styled-components";
import Check from "../icons/Check";

class Checkbox extends Component {
  render() {
    return (
      <CheckboxFrame onClick={this.props.onChange} >
        {this.props.checked ? (
          <div>
            <Check />
          </div>
        ) : (
          <div />
        )}
      </CheckboxFrame>
    );
  }
}

const CheckboxFrame = styled.div`
  height: 20px;
  width: 20px;
  border-radius: 4px;
  border: 1px solid #eee;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  cursor: pointer;
`;

export default Checkbox;
