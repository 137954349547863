import React, { Component } from "react";
import styled from "styled-components";
import Checkbox from "../../components/base/Checkbox";
import { MdKeyboardBackspace, MdMenu, MdHelpOutline, MdLock, MdBeenhere } from "react-icons/md";
import Spinner from "../../components/icons/InboxSpinner";

class BankID extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasApproved: false,
      loading: false
    };
  }
  render() {
    return (
      <ContentFrame>
        <Frame>
          <p style={{ fontWeight: 700, color: "#5E6996", fontSize: 12, marginLeft: 20 }}>HUSBANK</p>
          <IDFrame>
            <Header>
              <div style={{ marginLeft: 10, marginTop: 3 }}>
                <MdKeyboardBackspace color="#1C8ECC" size={28} />
              </div>
              <p style={{ fontWeight: 500, color: "#705454", marginLeft: 10 }}>Identifisering</p>
              <div style={{ flexGrow: 1 }} />
              <Menu>
                <p style={{ fontWeight: 300, fontSize: 13, marginRight: 4.5, color: "#555555" }}>Meny</p>
                <div style={{ marginTop: 5 }}>
                  <MdMenu color="#555555" size={20} />
                </div>
              </Menu>
            </Header>
            <IDContent>
              <div style={{ display: "flex", flexDirection: "row", alignItems: "center", width: "80%" }}>
                <p
                  style={{ fontSize: 16, letterSpacing: 0.2, fontWeight: 500, marginLeft: 10, marginBottom: 3, paddingBottom: 3 }}
                >
                  BankID på mobil
                </p>
                <div style={{ marginTop: 12, marginLeft: 3 }}>
                  <MdHelpOutline color="#4CA6D6" size={20} />
                </div>
              </div>
              <InputContent>
                <MockInput>
                  <div style={{ marginLeft: 10, marginTop: 5 }}>
                    <MdLock color="#0079c3" size={23} />
                  </div>
                  <p style={{ fontSize: 17, wordSpacing: 3, fontWeight: 500, marginLeft: 7, color: "#777777" }}>Fortsett</p>
                </MockInput>
                <div style={{ marginLeft: "3%" }} />
                {this.state.loading ? (
                  <Spinner />
                ) : (
                    <Button
                      onClick={() => {
                        this.setState({ loading: true });
                        setTimeout(() => this.props.onClick(), 1000);
                      }}
                      hasApproved={this.state.hasApproved}
                    >
                      <div style={{ transform: "rotate(180deg)", marginBottom: 10 }}>
                        <MdKeyboardBackspace color="#ffffff" size={28} />
                      </div>
                    </Button>
                  )}
              </InputContent>
              <div style={{ display: "flex", flexDirection: "row", alignItems: "center", marginTop: 5, width: "80%" }}>
                <div style={{ marginLeft: 8 }}>
                  <Checkbox
                    checked={this.state.hasApproved}
                    onChange={checked => this.setState({ hasApproved: !this.state.hasApproved })}
                  />
                </div>
                <p style={{ fontSize: 12, margin: 0, padding: 0, color: "#5F5F5F", fontWeight: 400, marginLeft: 10 }}>
                  Godta <a href="#">vilkår</a>
                </p>
              </div>
            </IDContent>
            <Footer>
              <Menu style={{ width: 30, marginLeft: 3 }}>
                <div style={{ marginTop: 2.5 }}>
                  <MdBeenhere color="#555555" size={16} />
                </div>
              </Menu>
              <div style={{ marginLeft: 3 }}>
                <p style={{ fontSize: 10, margin: 0, padding: 0, marginBottom: 1, color: "#5F5F5F" }}>BankID brukersted:</p>
                <p style={{ fontSize: 12, margin: 0, padding: 0, color: "#5F5F5F", fontWeight: 500 }}>Haugesund Sparebank</p>
              </div>
            </Footer>
          </IDFrame>
        </Frame>
      </ContentFrame>
    );
  }
}

const ContentFrame = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: -20px;
`;

const Button = styled.button`
  height: 37px;
  width: 17%;
  border-radius: 4px;
  background-color: ${props => (props.hasApproved ? "#0079c3" : "#ccc")};
  color: white;
  border: none;
  pointer-events: ${props => (props.hasApproved ? "auto" : "none")};
  cursor: ${props => (props.hasApproved ? "pointer" : "not-allowed")};
`;

const MockInput = styled.div`
  background-color: #efefef;
  border-radius: 4px;
  width: 80%;
  border: 1px solid #bebebe;
  height: 35px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const InputContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 80%;

  display: flex;
  flex-direction: row;
  align-items: center;
`;

const IDContent = styled.div`
  flex-grow: 1;
  width: 100%;
  height: calc(100% - 80px);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Menu = styled.div`
  height: 30px;
  width: 70px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  margin-right: 10px;
  border: 1px solid #c7c7c7;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

const Footer = styled.div`
  height: 40px;
  width: 100%;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-top: 1px solid #c7c7c7;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Header = styled.div`
  height: 40px;
  width: 100%;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom: 1px solid #c7c7c7;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const IDFrame = styled.div`
  width: 400px;
  height: 300px;
  border-radius: 4px;
  border: 1px solid #c7c7c7;
  background-color: #fbfbfb;
`;

const Frame = styled.div`
  height: 300px;
  width: 500px;
  margin-top: 20px;
  border-radius: 4px;
  padding-bottom: 50px;
  border: 1px solid #eee;
  margin-top: 30px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export default BankID;
