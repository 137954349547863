import React, { useState } from "react";
import styled from "styled-components";
import Radio from "../../../components/icons/Radio";
import { MEDIUMGRAY } from "../../../components/styles/colors";

export default function AccountEntry(props) {
  const [isSelected, setIsSelected] = useState(false);
  return (
    <MenuFrame>
      <div onClick={() => setIsSelected(!isSelected)} style={{ cursor: "pointer", marginTop: "5px" }}>
        <Radio checked={isSelected} color={"#ffa900"} />
      </div>
      <MenuTitle>{props.account}</MenuTitle>
    </MenuFrame>
  );
}

const MenuTitle = styled.h1`
  font-size: 18px;
  font-weight: 500;
`;

const MenuFrame = styled.div`
  display: grid;
  grid-template-columns: 40px 1fr 140px;
  align-items: center;
  width: 400px;
  border-bottom: 1px solid ${MEDIUMGRAY};
`;
