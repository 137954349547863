import React from "react";

export default class Done extends React.Component {
  render() {
    return (
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20Z"
          fill="#EB5E55"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.4103 12.7143H11.6026L11 9.60909V7H13V9.60909L12.4103 12.7143ZM12 17C10.9382 17 10.5 16.4375 10.5 15.7045V15.2955C10.5 14.5625 10.9382 14 12 14C13.0618 14 13.5 14.5625 13.5 15.2955V15.7045C13.5 16.4375 13.0618 17 12 17Z"
          fill="white"
        />
      </svg>
    );
  }
}
