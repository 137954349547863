import React from "react";
import styled from "styled-components";
import WorldIcon from "../../components/icons/World";
import { Button } from "../../components/styles/base";
import { Title, Text } from "../../components/styles/fonts";

export default function GiveConsent(props) {
  return (
    <Frame>
      <Icon>
        <WorldIcon />
      </Icon>
      <Title>ZTL Samtykke</Title>
      <Text size="22px">
        Vi trenger ditt samtykke for å innhente informasjon og utføre betalinger på dine kontoer. Samtykket gjelder kun gjennom
        ditt regnskapssystem.
      </Text>
      <Text size="22px">Samtykke må fornyes hver 90. dag.</Text>
      <Button onClick={() => props.handleNext("select-accounts")}>Next</Button>
      <Avtale>Avtalevilkår</Avtale>
    </Frame>
  );
}

const Frame = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Icon = styled.div`
  padding-top: 50px;
`;

const Avtale = styled.div`
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 150.6%;
  display: flex;
  align-items: center;
  text-align: center;
  text-decoration-line: underline;
`;
