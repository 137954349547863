import styled from "styled-components";
import { NOIR, COAL, COAL3, COALOPACITY } from "./colors";

const FontBase = styled.p`
  font-family: Muli;
  margin: 0;
  padding: 0;
  color: ${props => (props.color ? props.color : NOIR)};
`;


export const Title = styled(FontBase)`
  text-align: center;
  font-size: ${props => (props.size ? props.size : "32px")};
  font-weight: 600;
  line-height: 40px;
  padding: ${props => (props.padding ? props.padding : "30px 0px 0px 0px")};
`;

export const TitleMedium = styled(FontBase)`
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  line-height: 40px;
  padding-top: 30px;
  color: ${COAL3};
`;

export const Text = styled(FontBase)`
  text-align: ${props => (props.align ? props.align : "center")};
  font-size: ${props => (props.size ? props.size : "12px")};
  color: ${props => (props.color ? props.color : COAL)};
  width: ${props => (props.width ? props.width : "")};
  padding: ${props => (props.padding ? props.padding : "10px 60px 10px 60px")};
  line-height: 21px;
`;

export const TextSmall = styled(FontBase)`
  color: ${props => (props.color ? props.color : COALOPACITY)};
  font-size: 12px;
  line-height: 18px;
`;
